import React from 'react';
import { useQuery } from '../hooks/useQuery';

function ClbkArchive() {
  const data = useQuery();
  const clwcEvents = data.clbk.nodes;
  console.log(clwcEvents);

  const todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');

  return (
    <div class="clwc-classes-wrap">
      {clwcEvents.map((clwcEvent, index) => (
        <React.Fragment key={index}>
          {todaysDate <= clwcEvent.frontmatter.date ? (
            <div className="clwc-container">
              <div class="class-info">
                <h2>{clwcEvent.frontmatter.region}</h2>
                <p>
                  <strong>{clwcEvent.frontmatter.date3}</strong>
                </p>
                <p>{clwcEvent.frontmatter.start_time} Uhr</p>
                <h3>{clwcEvent.frontmatter.venue_name}</h3>
                <p>
                  {clwcEvent.frontmatter.venue_address}
                  <br />
                  {clwcEvent.frontmatter.venue_city}
                </p>
                <p className="mb-2">{clwcEvent.frontmatter.language}</p>
                <a href={clwcEvent.frontmatter.map_link} className="link">
                  +Google Maps
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default ClbkArchive;
