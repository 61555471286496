import React from 'react';
import { useQuery } from '../hooks/useQuery';
// import Calendar from "../images/calendar.svg";

function UpcomingEvents() {
  const data = useQuery();
  const upcomings = data.upcoming.nodes;
  let counter = 1;
  const totalEvents = 2;
  console.log(upcomings);

  /*const todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/")*/
  const todaysDate = new Date().toJSON();
  const epochDate = new Date().getTime();
  console.log(epochDate);
  console.log(todaysDate);

  return (
    <div className="event-list" id="upcoming-events">
      {upcomings.map((event, index) => (
        <>
          {console.log(event.frontmatter.computerDate)}
          {todaysDate <= event.frontmatter.computerDate &&
          counter <= totalEvents ? (
            <div key={index} className="event-info-container">
              <React.Fragment
                className={`${(counter = counter + 1)}`}
              ></React.Fragment>
              <div>
                <h4 className="mt-5">{event.frontmatter.subcategory}:</h4>
                <a
                  href={'/' + event.frontmatter.slug}
                  className="sub-event-container has-text-centered mt-3 mb-3 mr-6 ml-6"
                >
                  <div>
                    <h3 className="mt-3">{event.frontmatter.title}</h3>
                    <h2 className="m-1">{event.frontmatter.date3}</h2>
                    <h5 className="m-1">{event.frontmatter.venue_name}</h5>
                  </div>
                </a>
              </div>
            </div>
          ) : (
            <React.Fragment
              className={`{counter = counter -1}`}
            ></React.Fragment>
          )}
        </>
      ))}
    </div>
  );
}

export default UpcomingEvents;
