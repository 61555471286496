import { useStaticQuery, graphql } from 'gatsby';

export const useQuery = () => {
  const props = useStaticQuery(graphql`
    query MyQuery {
      musicArtists: allMdx(
        filter: { frontmatter: { category: { eq: "musicArtists" } } }
      ) {
        nodes {
          frontmatter {
            category
            artist
            img
            location
          }
          body
        }
      }
      mainEvents: allMdx(
        filter: { frontmatter: { subcategory: { eq: "hauptveranstaltung" } } }
        sort: { fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            subcategory
            title
            date3(formatString: "DD. MMM", locale: "de")
            start_time
            doors_open
            venue_address
            venue_name
            slug
          }
          body
        }
      }
      upcoming: allMdx(
        filter: {
          frontmatter: { subcategory: { eq: "vor der veranstaltung" } }
        }
        sort: { fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            subcategory
            title
            date
            date2
            computerDate: date3
            date3(formatString: "DD. MMM", locale: "de")
            start_time
            venue_address
            venue_name
            slug
            map_link
          }
          body
        }
      }
      events: allMdx(
        filter: { frontmatter: { category: { eq: "event" } } }
        sort: { order: ASC, fields: frontmatter___date }
      ) {
        nodes {
          frontmatter {
            category
            title
            date
            date2
            start_time
            venue_address
            venue_name
            slug
            map_link
          }
          body
        }
      }
      clbk: allMdx(
        filter: {
          frontmatter: { date: { ne: null }, category: { eq: "clbk" } }
        }
        sort: {
          order: [ASC, ASC]
          fields: [frontmatter___date, frontmatter___region]
        }
      ) {
        nodes {
          frontmatter {
            date
            date2
            date3(formatString: "dddd, D. MMMM", locale: "de")
            category
            language
            map_link
            region
            start_time
            template
            title
            venue_name
            venue_address
            venue_city
          }
          body
        }
      }
      clwc: allMdx(
        filter: {
          frontmatter: { date: { ne: null }, category: { eq: "clbk" } }
        }
        sort: {
          order: [ASC, ASC]
          fields: [frontmatter___date, frontmatter___region]
        }
      ) {
        nodes {
          frontmatter {
            date
            date2
            date3(formatString: "dddd, D. MMMM", locale: "en")
            category
            language
            map_link
            region
            start_time
            template
            title
            venue_name
            venue_address
            venue_city
          }
          body
        }
      }
    }
  `);
  return props;
};
