import React from 'react';
import { useQuery } from '../hooks/useQuery';

function MainEvents() {
  const data = useQuery();
  //console.log(data)
  const mainEvents = data.mainEvents.nodes;

  return (
    <>
      {/*<h1 className="has-text-centered mt-6">Hauptveranstaltung</h1>*/}
      {/* <p className="has-text-centered mt-2">
        Wstęp <strong>wolny</strong> i każdy jest mile widziany.
      </p> */}
      <div className="event-list" id="main-event">
        <div className="event-info-container mt-4">
          {mainEvents.map((mainEvent, index) => (
            <div key={index}>
              <h4 className="mt-5">LIVE MUSIK & EINE BOTSCHAFT DER HOFFNUNG</h4>
              <div className="event-info-tickets has-text-centered">
                <h3>Freier Eintritt — Ohne Tickets</h3>
              </div>
              <p className="has-text-centered more-info">
                <a href={mainEvent.frontmatter.slug}>Mehr Informationen zu Anreise und Parkplätzen hier.</a>
              </p>
              <a
                href={mainEvent.frontmatter.slug}
                className="main-event-container has-text-centered mt-3 mb-3 mr-6 ml-6"
              >
                <div>
                  <h3 className="mt-3">{mainEvent.frontmatter.title}</h3>
                  <h2 className="m-1">{mainEvent.frontmatter.date3}</h2>
                  <h3 className="m-1">
                    {mainEvent.frontmatter.start_time} (Einlass:{' '}
                    {mainEvent.frontmatter.doors_open})
                  </h3>
                  <h4></h4>
                  <h5 className="m-1">{mainEvent.frontmatter.venue_name}</h5>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MainEvents;
